export const modalBackdrop = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: '.3' } },
  exit: { opacity: 0 },
};

export const modalContainer = {
  initial: {
    y: 0,
    opacity: 0,
  },
  animate: {
    y: -100,
    opacity: '1',
    transition: { duration: '.5' },
  },
};
