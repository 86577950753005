import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsMegaphoneFill } from "react-icons/bs";

import { useAppContext } from "../../hooks/useAppContext";
import Logo from "../../assets/logo.png";
import { ReactComponent as Snap } from "../../assets/snap.svg";
import { ReactComponent as Whatsapp } from "../../assets/whatsapp.svg";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as Insta } from "../../assets/insta.svg";

import "./style.css";

const NavBar = () => {
  const { setOpen } = useAppContext();
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    if (openNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [openNav]);

  return (
    <nav>
      <div className="nav-wrapper">
        <div className="nav-alert-bar">
          <div className="nav-alert-content">
            🎯🎯🎯 Up to 98% Success rate!
          </div>
        </div>
        <div className="nav-container" style={{ color: "#000" }}>
          <span>
            <Link to="/">
              <img src={Logo} alt="" className="nav-img" />
            </Link>
          </span>
          <span>
            <span className="nav-blog">
              <Link to="/services">Our Services</Link>
            </span>
            <span className="nav-blog">
              <Link to="/pricing">Pricing</Link>
            </span>
            {/* <span className="nav-blog">
              <Link to="/blog">Blogs</Link>
            </span> */}
            <button
              className="bass-offer-card-button"
              style={{ width: "200px" }}
              onClick={() => setOpen(true)}
            >
              Get Started
            </button>
            <span
              onClick={() => setOpenNav(true)}
              className="mobile-menu-trigger"
            >
              <GiHamburgerMenu style={{ fontSize: "30px", color: "#1775d1" }} />
            </span>
          </span>
        </div>
        {openNav && (
          <div className="mobile-nav">
            <div className="mobile-nav-wrapper">
              <div className="d-flex">
                <img src={Logo} alt="" className="nav-img" />
                <span onClick={() => setOpenNav(!openNav)}>
                  <AiFillCloseCircle
                    style={{
                      fontSize: "30px",
                      color: "#1775d1",
                      marginTop: "10px",
                    }}
                  />
                </span>
              </div>
              <div>
                <ul>
                  <li>
                    <Link to="/" onClick={() => setOpenNav(false)}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">Our Services</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  {/* <li>
                    <Link to="/blog">Blogs</Link>
                  </li> */}
                  <li>
                    <Link to="/tandc">Terms and Conditions</Link>
                  </li>
                </ul>
              </div>
              <div className="socials-container">
                <div>Socials</div>
                <div
                  className="bass-socials-container"
                  style={{ marginTop: "20px" }}
                >
                  <a
                    href="https://www.snapchat.com/add/basshelp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Snap />
                  </a>
                  <a
                    href="https://www.instagram.com/bass_academic_help/?utm_medium=copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Insta />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/bass-academic-help/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Linkedin />
                  </a>
                  <a
                    href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Whatsapp />
                  </a>
                  <span>
                    <BsMegaphoneFill />
                    <span>Refer a friend!</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
