import { useState } from "react";
// import { Link } from "react-router-dom";
import { createClient } from "contentful";
import { IoLogoWhatsapp } from "react-icons/io";
// import { Fade } from 'react-reveal';

import { useAppContext } from "../hooks/useAppContext";
import Modal from "../Components/Modal";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";

import SnapImg from "../assets/Snap.png";
// import BlogImg from '../assets/blog.png';
import ChatImg from "../assets/chat.png";
import IG from "../assets/instagram.png";
import Assignment from "../assets/Assignment.png";
import linkedIn from "../assets/linkedIn.png";

// const client = createClient({
//   space: "mwvniygu5pd9",
//   accessToken: "6zDzXDd2zZEbszSjI2HheKqIM4YMXVA9aeRSkwJ54B0",
// });

export function Services() {
  const { open, setOpen } = useAppContext();
  // const [blogPost, setBlogPost] = useState([]);

  // useEffect(() => {
  //   const getAllBlogs = async () => {
  //     try {
  //       await client.getEntries().then((entries) => {
  //         setBlogPost(entries);
  //       });
  //     } catch (error) {
  //       console.log("ERROR FETCHING BLOG POSTS:", error);
  //     }
  //   };
  //   getAllBlogs();
  // }, []);

  return (
    <div>
      <header>
        <NavBar />
      </header>
      <main>
        <div style={{ backgroundColor: "#1F2045" }}>
          <div className="container">
            <h1 className="b-pricing">Our Services</h1>
            <p style={{ color: "#fff", marginBottom: "40px" }}>
              We know your time is valuable, and we want to be as affordable as
              possible. That's why we offer a variety of price plans to fit any
              budget, starting at just $10 per page. Our abstracts and expedited
              services also start at $10. However, we offer a lot of free
              services, such as:
            </p>
            <a
              href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
              className="pricing-button"
            >
              <IoLogoWhatsapp style={{ fontSize: "20px", marginTop: "10px" }} />
              <span>Get A Custom Price Now </span>
            </a>
          </div>
        </div>
        <div className="container">
          <div className="b-pricing-container b-pricing-features">
            <div className="b-pricing-card">
              <p>Essay Help</p>
              <div>
                We offer professional essay help to students from all over the
                globe. Our team of expert writers is ready to take on any
                assignment, no matter how difficult it might seem. We are
                confident that our highly qualified professionals will meet all
                your expectations. You can rest assured that we will deliver
                high-quality work within the deadline.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Assignment Writing</p>
              <div>
                If you need help with your assignments, we are here for you! Our
                assignment writing service is dedicated to providing clients
                with high-quality papers according to their specifications. We
                know how important it is for students to submit completed work
                on time, so we strive to ensure our papers are delivered before
                the deadline.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Dissertation Research</p>
              <div>
                Browse through our dissertation research section and learn more
                about the type of writing services we offer! We have a wide
                selection of topics available, so whether you need help with
                literature review or research methodology, our experts will be
                happy to assist you with anything related to dissertation
                writing.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Homework</p>
              <div>
                We can help you with your homework. We have a team of expert
                writers in different fields, such as mathematics, science, and
                arts. We also have a team of editors who will proofread your
                work to ensure there are no mistakes in spelling or grammar.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Research Proposal</p>
              <div>
                Do you need help writing a research proposal? Our experienced
                writers can provide you with a quality research proposal that
                will impress your professor and ensure your project grade is
                high. We guarantee that all of our work is original and
                plagiarism-free! You will receive a professionally written
                document ready for submission by your deadline.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Speech Writing</p>
              <div>
                Our speech-writing service offers high-quality speeches at an
                affordable price! We have a team of talented writers who
                specialise in writing speeches for college students and
                professionals. Our speech writing services include free
                revisions until you are satisfied with the final product!
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Bibliography</p>
              <div>
                We'll write your bibliography for you, so all you have to do is
                fill in the blanks and add a few of your best papers. No more
                pulling your hair out over those pesky footnotes!
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Motivation Letters</p>
              <div>
                We know how important it is to have a strong motivation letter
                to help you stand out from the crowd. We can write one for you
                that highlights your talents and makes sure that your
                application gets noticed by admissions officers.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Personal Statements</p>
              <div>
                When it comes to personal statements, there's no such thing as
                too much information—we want to know everything about you! Let
                us know what makes you unique and special, and we'll craft a
                personal statement that will wow admissions officers and set you
                apart from other applicants.
              </div>
            </div>
          </div>
        </div>
        <div className="b-socials-section">
          <div className="container">
            <p className="bass-answers-section">We'd love to hear from you</p>
            <div className="b-socials-platforms">
              <a
                href="https://www.snapchat.com/add/basshelp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={SnapImg} alt="" />
                <p>Were on Snap</p>
                <span>@basshelp</span>
              </a>
              <a
                href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ChatImg} alt="" />
                <p>Chat us</p>
                <span>On WhatsApp</span>
              </a>
              {/* <Link to="/blog">
                <img src={BlogImg} alt="" />
                <p>Learn Stuff</p>
                <span>Our Blog</span>
              </Link> */}
              <div rel="noopener noreferrer" onClick={() => setOpen(true)}>
                <img src={Assignment} alt="" />
                <p>Submit a Task</p>
                <span>Get Started</span>
              </div>
              <a
                href="https://www.instagram.com/bass_academic_help/?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IG} alt="" />
                <p>Send a DM</p>
                <span>@bass_academic_help</span>
              </a>
              <a
                href="https://www.linkedin.com/company/bass-academic-help/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedIn} alt="" />
                <p>Check us out</p>
                <span>On LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
        {/* <div
          style={{ marginBottom: "20px", marginTop: "40px" }}
          className="blog-width"
        >
          <p className="bass-answers-section" style={{ paddingTop: "30px" }}>
            Recent Blog Posts
          </p>
          <div className="container">
            <div className="b-container">
              {blogPost?.items?.slice(0, 3).map((post) => (
                <div key={post?.sys?.id} className="b-child">
                  <Link to={`/blog/${post.sys.id}`}>
                    <img
                      src={post?.fields?.blogImage?.fields?.file?.url}
                      alt={post?.fields?.blogTitle}
                      style={{ width: "100%", height: "200px" }}
                    />
                    <div className="blog-details">
                      <p className="blog-title">{post?.fields?.blogTitle}</p>
                      <p className="b-summary">
                        {post?.fields?.blogSummary.substring(0, 60)}
                      </p>
                      <div className="b-date">
                        {post?.fields?.createdDate === undefined
                          ? "loading"
                          : new Intl.DateTimeFormat("en-GB", {
                              month: "long",
                              day: "2-digit",
                              year: "numeric",
                            }).format(new Date(post?.fields?.createdDate))}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div style={{ backgroundColor: "#1F2045" }}>
          <div className="bass-offer-card">
            <div>
              <h1>Your one-stop help for all academic needs</h1>
            </div>
            <div className="bass-offer-card-details">
              <div className="bass-offer-card-info">
                <div>Starting at</div>
                <p>$10</p>
              </div>
              <button
                className="bass-offer-price-button"
                onClick={() => setOpen(true)}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Modal open={open} setOpen={setOpen} />
    </div>
  );
}
