import React, { useState, useRef } from 'react';
import './styles.css';

export const Accordion = ({ title, subtitle, children }) => {
  const content = useRef(null);

  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0');

  function toggleAccordion() {
    setActive(active === '' ? 'active' : '');
    setHeight(active === '' ? `${content.current.scrollHeight}px` : '0px');
  }

  return (
    <div className="accordion__section">
      <button
        className={`bass-accordion-container ${active}`}
        style={{
          backgroundColor: active === '' ? '#F5F6F8' : '#1775d1',
          color: active === '' ? '#1F2045' : '#fff',
        }}
        onClick={toggleAccordion}
      >
        <div className="">
          <p className="bass-accordion-header">{title}</p>
        </div>
        <p className="accordion_title">
          {active === '' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              height={30}
              className="w-6 h-6"
              style={{ color: '#1F2045' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.5"
              height={30}
              stroke="currentColor"
              className="w-6 h-6"
              style={{ color: '#1F2045' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          )}
        </p>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <div>{children}</div>
      </div>
    </div>
  );
};
