import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

import { modalBackdrop } from './motion';

import './styles.css';

const Modal = ({ open, setOpen }) => {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_b7cqn57',
        'template_qm2ohwr',
        form.current,
        'x1GIdilBicfq7o8J2'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setOpen(false);
    setUserName('');
    setEmail('');
    setPhone('');
    setDescription('');
    toast.info('Email Delivered, Expect a response as soon as possible');
  };

  const closeModal = () => {
    setOpen(false);
    setUserName('');
    setEmail('');
    setPhone('');
    setDescription('');
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          variants={modalBackdrop}
          className="modal"
          style={{ zIndex: 1000000 }}
        >
          <motion.div
            initial={{
              y: 0,
              opacity: 0,
            }}
            animate={{
              y: -100,
              opacity: '1',
              transition: { duration: '.5' },
            }}
            className="modal-content"
          >
            <form ref={form} onSubmit={sendEmail}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h2 style={{ color: '#000' }}>Complete the form</h2>
                <span
                  onClick={closeModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#ff231f',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  Close
                </span>
              </div>

              <label htmlFor="email" style={{ color: '#3d3d3d' }}>
                Name
              </label>
              <input
                type="text"
                placeholder="Name"
                name="user_name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="bass-input"
                required
              />
              <div
                style={{
                  marginBottom: '10px',
                  color: '#4caf50',
                  fontSize: '12px',
                }}
              >
                NB: Could be a nickname... for confidentiality sale
              </div>

              <label htmlFor="email" style={{ color: '#3d3d3d' }}>
                Email
              </label>
              <input
                type="email"
                placeholder="Email Address"
                name="user_email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bass-input"
                required
              />

              <label htmlFor="email" style={{ color: '#3d3d3d' }}>
                Phone Number
              </label>
              <input
                type="number"
                placeholder="Phone Number"
                name="phone_number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="bass-input"
                required
              />
              <div
                style={{
                  marginBottom: '20px',
                  color: '#1cb900',
                  fontSize: '12px',
                }}
              >
                Preferrably WhatsApp Number
              </div>

              <label htmlFor="email" style={{ color: '#3d3d3d' }}>
                Project Description
              </label>
              <textarea
                name="message"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
                placeholder="Feel free to explain the project scope here or add a demo link to thee project"
              ></textarea>
              <input
                type="submit"
                className="bass-offer-price-button"
                value="Submit"
                style={{ width: '100%' }}
              />
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
