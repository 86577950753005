import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

export const useAppContext = () => {
  const { open, slide, setSlide, setOpen } = useContext(AppContext);

  return {
    open,
    slide,
    setSlide,
    setOpen,
  };
};
