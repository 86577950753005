// import { useState } from "react";
import Marquee from "react-fast-marquee";
// import { Link } from "react-router-dom";
// import { createClient } from "contentful";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiMailSendFill } from "react-icons/ri";
// import { Fade } from 'react-reveal';
// import Carousel from 'react-elastic-carousel';

import { useAppContext } from "../hooks/useAppContext";
import { Accordion } from "../Components/Carousel";
import Modal from "../Components/Modal";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";

import Image1 from "../assets/Image1.png";
import Image2 from "../assets/Image2.png";
import Image3 from "../assets/Image3.png";
import SnapImg from "../assets/Snap.png";
// import BlogImg from "../assets/blog.png";
import ChatImg from "../assets/chat.png";
import IG from "../assets/instagram.png";
import Assignment from "../assets/Assignment.png";
import linkedIn from "../assets/linkedIn.png";
import { data, faq, www } from "../data";

// const client = createClient({
//   space: 'mwvniygu5pd9',
//   accessToken: '6zDzXDd2zZEbszSjI2HheKqIM4YMXVA9aeRSkwJ54B0',
// });

export function Home() {
  const { open, setOpen } = useAppContext();
  // const [blogPost, setBlogPost] = useState([]);

  // useEffect(() => {
  //   const getAllBlogs = async () => {
  //     try {
  //       await client.getEntries().then((entries) => {
  //         setBlogPost(entries);
  //       });
  //     } catch (error) {
  //       console.log("ERROR FETCHING BLOG POSTS:", error);
  //     }
  //   };
  //   getAllBlogs();
  // }, []);

  return (
    <div>
      <header>
        <NavBar />
      </header>
      <main>
        <div>
          <div className="bass-hero-bg">
            <div className="container bass-landing-page">
              <p className="landing-page-header">
                Hire professionals to do your coursework without breaking the
                bank
              </p>
              <div className="landing-page-sub">
                We have a team of experts who are specialists in their field and
                will be able to provide you with outstanding results
              </div>
              <div className="button-containers">
                <button
                  className="landing-page-cta f-width"
                  onClick={() => setOpen(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                    gap: "10px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <RiMailSendFill style={{ fontSize: "25px" }} />
                  Submit an Assignment
                </button>
                <a
                  href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="landing-page-cta2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                    gap: "10px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <IoLogoWhatsapp style={{ fontSize: "20px" }} />
                  <span>Message us on WhatsApp </span>
                </a>
              </div>
            </div>
          </div>
          <div className="landing-our-services">
            <div className="container">
              <div className="b-qualities">
                <div className="b-qualities-child">
                  <p>Who are we?</p>
                  <div>
                    Bass Academic Help is an educational consulting firm that
                    provides excellent academic help to English-taught graduate
                    students across Europe and America. Our experienced writers
                    and researchers hold a minimum of a Master's Degree in their
                    respective fields, making it possible for us to help
                    students across disciplines excellently.
                  </div>
                </div>
                <div className="b-qualities-child">
                  <p>What we do? </p>
                  <div>
                    Services we offer include assistance with all written
                    coursework; essay, dissertation, discussion board,
                    progressive notes, thesis, projects and assignments. We
                    value our customers' success and privacy.
                  </div>
                </div>
              </div>
              <p className="services-heading">Our Services</p>
              <div className="service-desc">
                <p>
                  We are the best assignment writing service on the internet. We
                  have a team of experts who are specialists in their field and
                  will be able to provide you with outstanding results.
                </p>
                <p>
                  We aim to ensure that our customers get the best assignment
                  writing services, and we always strive to provide the best
                  services possible.
                </p>
                <p>
                  Our writers are qualified and experienced professionals who
                  have worked in various fields before they started working with
                  us. They know precisely what they need to do to meet your
                  expectations and deliver quality content every time. We also
                  offer discounts on bulk orders as well as discounts for
                  first-time users, so if you are looking for an assignment
                  writing service that can help you achieve excellent results,
                  then look no further than us!
                </p>
              </div>

              <Marquee loop="infinite" speed="100">
                {data.map(({ id, title }) => (
                  <span key={id} className="services-pill" style={{}}>
                    {title}
                  </span>
                ))}
              </Marquee>
            </div>
          </div>
          <div className="bass-how-it-works">
            <div className="container">
              <p className="bass-heading">How it works</p>
              <div className="how-it-works-container">
                <div className="how-it-works-child">
                  <img src={Image1} alt="" className="how-illustration" />
                  <p>1. Give us the assignment details</p>
                  <div>Topic, word count, areas to cover, deadline, etc.</div>
                </div>
                <div className="how-it-works-child">
                  <img src={Image2} alt="" className="how-illustration" />
                  <p>2. Provide your contact details</p>
                  <div>Email address, first name, and phone number.</div>
                </div>
                <div className="how-it-works-child">
                  <img src={Image3} alt="" className="how-illustration" />
                  <p>3. We'll send you a quote</p>
                  <div>Click the button below to get started</div>
                  <button
                    className="landing-page-cta f-width"
                    onClick={() => setOpen(true)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                      gap: "10px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <RiMailSendFill style={{ fontSize: "25px" }} />
                    Submit an Assignment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="b-socials-section">
          <div className="container">
            <p className="bass-answers-section">We'd love to hear from you</p>
            <div className="b-socials-platforms">
              <a
                href="https://www.snapchat.com/add/basshelp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={SnapImg} alt="" />
                <p>Were on Snap</p>
                <span>@basshelp</span>
              </a>
              <a
                href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ChatImg} alt="" />
                <p>Chat us</p>
                <span>On WhatsApp</span>
              </a>
              {/* <Link to="/blog">
                <img src={BlogImg} alt="" />
                <p>Learn Stuff</p>
                <span>Our Blog</span>
              </Link> */}
              <div rel="noopener noreferrer" onClick={() => setOpen(true)}>
                <img src={Assignment} alt="" />
                <p>Submit a Task</p>
                <span>Get Started</span>
              </div>
              <a
                href="https://www.instagram.com/bass_academic_help/?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IG} alt="" />
                <p>Send a DM</p>
                <span>@bass_academic_help</span>
              </a>
              <a
                href="https://www.linkedin.com/company/bass-academic-help/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedIn} alt="" />
                <p>Check us out</p>
                <span>On LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
        {/* <div
          style={{ marginBottom: "20px", marginTop: "40px" }}
          className="blog-width"
        >
          <p className="bass-answers-section" style={{ paddingTop: "30px" }}>
            Recent Blog Posts
          </p>
          <div className="container">
            <div className="b-container">
              {blogPost?.items?.slice(0, 3).map((post) => (
                <div key={post?.sys?.id} className="b-child">
                  <Link to={`/blog/${post.sys.id}`}>
                    <img
                      src={post?.fields?.blogImage?.fields?.file?.url}
                      alt={post?.fields?.blogTitle}
                      style={{ width: "100%", height: "200px" }}
                    />
                    <div className="blog-details">
                      <p className="blog-title">{post?.fields?.blogTitle}</p>
                      <p className="b-summary">
                        {post?.fields?.blogSummary.substring(0, 60)}
                      </p>
                      <div className="b-date">
                        {post?.fields?.createdDate === undefined
                          ? "loading"
                          : new Intl.DateTimeFormat("en-GB", {
                              month: "long",
                              day: "2-digit",
                              year: "numeric",
                            }).format(new Date(post?.fields?.createdDate))}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div className="bass-faq-container">
          <p className="bass-answers-section">Answers to your Questions</p>
          <div className="container">
            {faq.map(({ id, title, answer }) => (
              <Accordion key={id} title={title}>
                {answer}
              </Accordion>
            ))}
          </div>
        </div>
        <div className="container">
          <p className="bass-answers-section">Why work with us</p>
          <div className="w-container">
            {www.map(({ id, title, body }) => (
              <div className="w-child" key={id}>
                <div>
                  <p>{title}</p>
                  <div>{body}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container mt-5">
          <p className="bass-answers-section">What your mates are saying</p>
          {/* <Carousel itemsToShow={1}>
            <div className="review">
              <Fade>
                <h3>
                  "When I requested a quote from Bass Academic Help, I didn't
                  expect to receive it as soon as I did, but that was not the
                  only thing that surprised me. The price was relatively
                  inexpensive, making me sceptical about the delivery quality.
                  Again, I was astonished to see how well-researched the
                  assignment was. I highly recommend Bass Academic Help."
                </h3>
                <p>-- Ola, NY</p>
              </Fade>
            </div>
            <div className="review">
              <Fade>
                <h3>
                  "I had a fast-approaching deadline, and I knew I couldn't meet
                  up alone. A friend told me to try Bass Academic Help. I
                  reluctantly did. I gave them the necessary information, and
                  the writer assigned me was very professional. She delivered in
                  time, and the deliverable was excellent."
                </h3>
                <p>-- Rogers, San Jose</p>
              </Fade>
            </div>
            <div className="review">
              <h3>
                "Great work on my assignment. My supervisor glanced through it
                and smiled satisfactorily. It's the first time he has reviewed
                submissions without making corrections or condemning my work."
              </h3>
              <p>-- Sebastian / New Mexico</p>
            </div>
            <div className="review">
              <Fade>
                <h3>
                  "I had an assignment and didn't know what to write. I reached
                  out to Bass Academic Help and was assigned a writer. The
                  writer helped me choose an ideal topic and did justice to the
                  topic in record time. I recommended a coursemate, and she
                  received the same sterling service. Bass Academic Help is
                  nothing short of the best."
                </h3>
                <p>-- Peter, Albuquerque</p>
              </Fade>
            </div>
            <div className="review">
              <Fade>
                <h3>
                  "I have used Bass Academic Help multiple times and worked with
                  different writers. One constant thing about all writers is the
                  speed at which they provide quality work. You'll barely find
                  any corrections in their work, and the services are
                  affordable. Great service!"
                </h3>
                <p>-- Ameedah, Washington</p>
              </Fade>
            </div>
          </Carousel> */}
          {/* <div className="w-container">
            {www.map(({ id, title, body }) => (
              <div className="w-child" key={id}>
                <Fade duration={1500}>
                  <p>{title}</p>
                  <div>{body}</div>
                </Fade>
              </div>
            ))}
          </div> */}
        </div>
        <div style={{ backgroundColor: "#1F2045", marginTop: "70px" }}>
          <div className="bass-offer-card">
            <div>
              <h1>Your one-stop help for all academic needs</h1>
            </div>
            <div className="bass-offer-card-details">
              <div className="bass-offer-card-info">
                <div>Starting at</div>
                <p>$10</p>
              </div>
              <button
                className="bass-offer-price-button"
                onClick={() => setOpen(true)}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Modal open={open} setOpen={setOpen} />
    </div>
  );
}
