import { Route, Routes } from "react-router-dom";

import { AppProvider } from "./context/AppContext";
import { Home } from "./Pages/Home";
// import { Blog } from "./Pages/Blog";
// import { BlogList } from "./Pages/BlogList";
import { Pricing } from "./Pages/Pricing";
import { Services } from "./Pages/Services";

function App() {
  return (
    <AppProvider>
      <Routes>
        {/* <Route path="/blog/:id" element={<Blog />} /> */}
        {/* <Route path="/blog" element={<BlogList />} /> */}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </AppProvider>
  );
}

export default App;
