// import { useState } from "react";
// import { Link } from "react-router-dom";
// import { createClient } from "contentful";
import { IoLogoWhatsapp } from "react-icons/io";
// import { Fade } from 'react-reveal';

import { useAppContext } from "../hooks/useAppContext";
import Modal from "../Components/Modal";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";

import SnapImg from "../assets/Snap.png";
// import BlogImg from '../assets/blog.png';
import ChatImg from "../assets/chat.png";
import IG from "../assets/instagram.png";
import Assignment from "../assets/Assignment.png";
import linkedIn from "../assets/linkedIn.png";

// const client = createClient({
//   space: "mwvniygu5pd9",
//   accessToken: "6zDzXDd2zZEbszSjI2HheKqIM4YMXVA9aeRSkwJ54B0",
// });

export function Pricing() {
  const { open, setOpen } = useAppContext();
  // const [blogPost, setBlogPost] = useState([]);

  // useEffect(() => {
  //   const getAllBlogs = async () => {
  //     try {
  //       await client.getEntries().then((entries) => {
  //         setBlogPost(entries);
  //       });
  //     } catch (error) {
  //       console.log("ERROR FETCHING BLOG POSTS:", error);
  //     }
  //   };
  //   getAllBlogs();
  // }, []);

  return (
    <div>
      <header>
        <NavBar />
      </header>
      <main>
        <div style={{ backgroundColor: "#1F2045" }}>
          <div className="container">
            <h1 className="b-pricing">Pricing</h1>
            <p style={{ color: "#fff", marginBottom: "40px" }}>
              We know your time is valuable, and we want to be as affordable as
              possible. That's why we offer a variety of price plans to fit any
              budget, starting at just $10 per page. Our abstracts and expedited
              services also start at $10. However, we offer a lot of free
              services, such as:
            </p>
            <a
              href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
              className="pricing-button"
            >
              <IoLogoWhatsapp style={{ fontSize: "20px", marginTop: "10px" }} />
              <span>Get A Custom Price Now </span>
            </a>
          </div>
        </div>
        <div className="container">
          <div className="b-pricing-container b-pricing-features">
            <div className="b-pricing-card">
              <p>Free Outline</p>
              <div>
                We believe that the outline is an essential part of the writing
                process. That's why we give you a free outline for every essay
                you order with us. If you need more than one outline, we will
                provide them at no extra cost. We'll send you a spreadsheet with
                all your outlines so you can get organised and stay on top of
                your work!
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Free Formatting</p>
              <div>
                Our writers are experts at formatting papers in MLA, APA,
                Chicago/Turabian or CSE styles—and they're happy to do it for
                free! Just let us know which style you prefer, and we'll format
                your paper for free (no extra charge). We also offer free title
                pages and reference pages when applicable.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Free Revisions</p>
              <div>
                We will revise your paper for free until you are satisfied with
                the final result. You can revise your paper as often as possible
                until it meets your expectations.{" "}
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Free Submission by Chapters</p>
              <div>
                You can submit your paper in parts, and we will grade each
                chapter separately, so you don’t have to wait to get feedback on
                a large assignment.
              </div>
            </div>
            <div className="b-pricing-card">
              <p>Free Plagiarism Report</p>
              <div>
                When you order any of our services, we provide a plagiarism
                report that guarantees you will not get caught cheating in class
                or at work. The report will show you where your paper is unique
                and where it has been copied from other sources.
              </div>
            </div>
          </div>
        </div>
        <div className="b-socials-section">
          <div className="container">
            <p className="bass-answers-section">We'd love to hear from you</p>
            <div className="b-socials-platforms">
              <a
                href="https://www.snapchat.com/add/basshelp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={SnapImg} alt="" />
                <p>Were on Snap</p>
                <span>@basshelp</span>
              </a>
              <a
                href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ChatImg} alt="" />
                <p>Chat us</p>
                <span>On WhatsApp</span>
              </a>
              {/* <Link to="/blog">
                <img src={BlogImg} alt="" />
                <p>Learn Stuff</p>
                <span>Our Blog</span>
              </Link> */}
              <div rel="noopener noreferrer" onClick={() => setOpen(true)}>
                <img src={Assignment} alt="" />
                <p>Submit a Task</p>
                <span>Get Started</span>
              </div>
              <a
                href="https://www.instagram.com/bass_academic_help/?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IG} alt="" />
                <p>Send a DM</p>
                <span>@bass_academic_help</span>
              </a>
              <a
                href="https://www.linkedin.com/company/bass-academic-help/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedIn} alt="" />
                <p>Check us out</p>
                <span>On LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
        {/* <div
          style={{ marginBottom: "20px", marginTop: "40px" }}
          className="blog-width"
        >
          <p className="bass-answers-section" style={{ paddingTop: "30px" }}>
            Recent Blog Posts
          </p>
          <div className="container">
            <div className="b-container">
              {blogPost?.items?.slice(0, 3).map((post) => (
                <div key={post?.sys?.id} className="b-child">
                  <Link to={`/blog/${post.sys.id}`}>
                    <img
                      src={post?.fields?.blogImage?.fields?.file?.url}
                      alt={post?.fields?.blogTitle}
                      style={{ width: "100%", height: "200px" }}
                    />
                    <div className="blog-details">
                      <p className="blog-title">{post?.fields?.blogTitle}</p>
                      <p className="b-summary">
                        {post?.fields?.blogSummary.substring(0, 60)}
                      </p>
                      <div className="b-date">
                        {post?.fields?.createdDate === undefined
                          ? "loading"
                          : new Intl.DateTimeFormat("en-GB", {
                              month: "long",
                              day: "2-digit",
                              year: "numeric",
                            }).format(new Date(post?.fields?.createdDate))}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div style={{ backgroundColor: "#1F2045" }}>
          <div className="bass-offer-card">
            <div>
              <h1>Your one-stop help for all academic needs</h1>
            </div>
            <div className="bass-offer-card-details">
              <div className="bass-offer-card-info">
                <div>Starting at</div>
                <p>$10</p>
              </div>
              <button
                className="bass-offer-price-button"
                onClick={() => setOpen(true)}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Modal open={open} setOpen={setOpen} />
    </div>
  );
}
