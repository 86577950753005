export const faq = [
  {
    id: 1,
    title: 'Why should I trust Bass Academic Help?',
    answer:
      'Our customers are a top priority. We ensure you get the best when you give us your assignment. From our support staff to the platform interface and well-vetted writers, we are dedicated to giving you more than a perfectly crafted assignment. ',
  },
  {
    id: 2,
    title: 'How much does it cost to do my assignment?',
    answer:
      'We charge you based on the context of the work you need to do. When you give us your project details, we will send you a quote before you start. ',
  },
  {
    id: 3,
    title: 'What if someone knows I paid for an assignment?',
    answer:
      "Our writers have a zero-plagiarism policy such that no one (Turnitin, CopyScape, your Professor, or anyone else) will know you didn't write it yourself. ",
  },
];

export const www = [
  {
    id: 1,
    title: 'Guaranteed Satisfaction',
    body: 'Our writers ensure that they understand your requirements before commencing. They carry you throughout the writing process to ensure you get a perfect job. We also let you do unlimited reviews of your assignment with a money-back guarantee if you are not pleased.',
  },
  {
    id: 2,
    title: 'Quick Turnaround',
    body: 'We have a deep pool of professional writers from different fields with years of experience. So there`s never a shortage of experts, and you get your assignment faster than you can snap your fingers. ',
  },
  {
    id: 3,
    title: 'Unique Writing',
    body: 'We have a zero-plagiarism custom that we hold in high esteem. All writers will always verify their assignment`s authenticity before submitting it.',
  },
];

export const data = [
  {
    id: 0,
    title: 'Coding Projects',
  },
  {
    id: 1,
    title: 'Essay Help',
  },
  {
    id: 2,
    title: 'Assignment Writing',
  },
  {
    id: 3,
    title: 'Dissertation Research',
  },
  {
    id: 4,
    title: 'Homework',
  },
  {
    id: 5,
    title: 'Research Proposal',
  },
  {
    id: 6,
    title: 'Speech Writing',
  },
  {
    id: 7,
    title: 'Bibliography',
  },
  {
    id: 8,
    title: 'Research Work',
  },
  {
    id: 9,
    title: 'Thesis',
  },
  {
    id: 10,
    title: 'Project Work',
  },
  {
    id: 11,
    title: 'Proof Reading',
  },
  {
    id: 12,
    title: 'Term Paper',
  },
];
