import { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [slide, setSlide] = useState(false);

  return (
    <AppContext.Provider
      value={{
        open,
        slide,
        setSlide,
        setOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
