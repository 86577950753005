import React from "react";
import { BsMegaphoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";
import { ReactComponent as Snap } from "../../assets/snap.svg";
import { ReactComponent as Whatsapp } from "../../assets/whatsapp.svg";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as Insta } from "../../assets/insta.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="bass-footer-wrapper">
          <div className="bass-footer-section">
            <Link to="/">
              <img src={logo} alt="Bars Academy Logo" className="bass-logo" />
            </Link>
          </div>
          <div className="bass-footer-section">
            <p>Links</p>
            {/* <a
              href="/blog"
              rel="noopener noreferrer"
              className="bass-footer-links"
            >
              Blog
            </a> */}
            <a href="/" rel="noopener noreferrer" className="bass-footer-links">
              Legal
            </a>
            <a href="/" rel="noopener noreferrer" className="bass-footer-links">
              Terms and Conditions
            </a>
            <a href="mailto:bassacademichelp@gmail.com" target="_blank" rel="noopener noreferrer" className="bass-footer-links">
              Mail us
            </a>
          </div>
          <div className="bass-footer-section">
            <p>Location</p>
            <div>Boston, USA</div>
          </div>
          <div className="bass-footer-section">
            <p>Socials</p>
            <div className="bass-socials-container">
              <a
                href="https://www.snapchat.com/add/basshelp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Snap />
              </a>
              <a
                href="https://www.instagram.com/bass_academic_help/?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Insta />
              </a>
              <a
                href="https://www.linkedin.com/company/bass-academic-help/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin />
              </a>
              <a
                href="https://api.whatsapp.com/message/BX4LTNTLPBU7O1?autoload=1&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Whatsapp />
              </a>
              <span>
                <BsMegaphoneFill />
                <span>Refer a friend!</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
